import { useQuery } from '@apollo/client';
import { func, string } from 'prop-types';
import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { Grid } from '@material-ui/core';

import { HandleError } from 'components/Common/Error';
import Skeleton, { SkeletonVariant } from 'components/Common/Skeleton';
import Text from 'components/Common/Text';
import { ContestList } from 'components/Contest/ContestList/List/ContestList';

import { GET_CONTESTS } from 'api/contest';
import s from 'components/Contest/ContestList/Container/ContestListContainer.module.scss';

const renderContests = (contestData, entryMax, setItem, handleClickOpen) => {
  const filteredContests = contestData.filter((item) => item?.entry?.max === entryMax);

  if (filteredContests.length === 0) {
    return (
      <Grid container xs={12} className={s.root}>
        <Text variant="h5" component="h2">
          No New Contests Currently Available
        </Text>
      </Grid>
    );
  }

  return filteredContests.map((item) => (
    <Grid container align="center" xs={12} className={s.root} key={item.contestId}>
      <ContestList item={item} setItem={setItem} handleClickOpen={handleClickOpen} />
    </Grid>
  ));
};

export const ContestListContainer = ({ sport, setItem, handleClickOpen }) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1199px)');
  const isMobile = useMediaQuery('(max-width: 599px)');

  const { loading, error, data } = useQuery(GET_CONTESTS, { fetchPolicy: 'network-only' });

  if (loading) return <Skeleton height={90} numberOfRows={5} variant={SkeletonVariant.RECT} />;
  if (error) {
    return <HandleError error={error} />;
  }

  const contestData = data[sport]?.values || [];

  return (
    <>
      {isDesktop && (
        // This is for Desktop
        <Grid container xs={12} justifyContent="space-evenly">
          <Grid item container xs={4} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              2 Team Leagues
            </Grid>
            {renderContests(contestData, 2, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={4} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              8 Team Leagues
            </Grid>
            {renderContests(contestData, 8, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={4} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              12 Team Leagues
            </Grid>
            {renderContests(contestData, 12, setItem, handleClickOpen)}
          </Grid>
        </Grid>
      )}

      {isTablet && (
        // This is for Tablet
        <Grid container xs={12} justifyContent="space-evenly">
          <Grid item container xs={6} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              2 Team Leagues
            </Grid>
            {renderContests(contestData, 2, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={6} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              8 Team Leagues
            </Grid>
            {renderContests(contestData, 8, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={12} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              12 Team Leagues
            </Grid>
            {renderContests(contestData, 12, setItem, handleClickOpen)}
          </Grid>
        </Grid>
      )}

      {isMobile && (
        // This is for Mobile
        <Grid container xs={12}>
          <Grid item container xs={12} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              2 Team Leagues
            </Grid>
            {renderContests(contestData, 2, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={12} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              8 Team Leagues
            </Grid>
            {renderContests(contestData, 8, setItem, handleClickOpen)}
          </Grid>

          <Grid item container xs={12} className={s.root}>
            <Grid item xs={12} align="center" variant="h5" component="h1">
              12 Team Leagues
            </Grid>
            {renderContests(contestData, 12, setItem, handleClickOpen)}
          </Grid>
        </Grid>
      )}
    </>
  );
};

ContestListContainer.propTypes = {
  sport: string,
  setItem: func,
  handleClickOpen: func,
};

export default ContestListContainer;
