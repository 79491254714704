import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { Button, Grid, TextField, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import { useSnackbar } from 'components/Common/Snackbar/useSnackbar';
import { GET_PLAYERS } from './queries';
import dummyArray from 'routes/League/LeagueWaiverWire/dummyArray';

export const PlayerPositionFilter = (props) => {
  let { positions, setFilteredPositions, filteredPositions, setFilteredPlayerId, handleListView, showQueue, className } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchValue, setSearchValue] = useState('');
  const { openSnackbar, snackbarProps, open: snackbarOpen } = useSnackbar();

  const classes = useStyles();

  // const { data, error, loading } = useQuery(GET_PLAYERS, {
  //   variables: {
  //     filter: { sport: { eq: 'nfl' }, averageDraftPositionPpr: { gt: 0 }, fantasyPosition: { in: filteredPositions } },
  //   },
  // });

  const handleClickAll = () => {
    setFilteredPositions(['QB', 'RB', 'WR', 'TE']);
  };

  const handlePositionToggle = (position) => {
    setFilteredPositions([position]);
  };

  const handlePlayerFilter = (playerValue) => {
    if (playerValue) {
      setFilteredPlayerId(playerValue.value); 
    } else {
      setFilteredPlayerId(null);
    }
  };

  const options = dummyArray.players.filter((player) =>
    player.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const buttons = positions.map((p) => {
    return (
      <Button
        aria-label={`${p} button`}
        variant='contained'
        className={classes.button}
        key={p}
        color={filteredPositions.includes(p) && filteredPositions.length < 4 ? 'primary' : 'secondary'}
        onClick={() => handlePositionToggle(p)}>
        {p}
      </Button>
    );
  });

  // if (error) {
  //   openSnackbar({ message: 'Error fetching players.', variant: 'error' });
  // }

  return (
    <Grid mt={1} ml={-1} mr={-1} container spacing={2} className={[classes.root, className].join(' ')}>
      <Grid order={{ xs: 1, md: 2 }} item container xs={12} lg={6} className={classes.one}>
        <Grid item xs={12}>
          <Autocomplete
            autoComplete
            // disabled={loading}
            options={options}
            getOptionLabel={(option) => option.fullName}
            onChange={(e, newValue) => handlePlayerFilter(newValue)}
            renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" placeholder="Search Players" className={classes.input}/>
          )}
          />
        </Grid>
        {handleListView && (
          <Grid item xs={12}>
            <Button
              className={classes.queueButton}
              variant={showQueue ? 'outlined' : 'contained'}
              color={!showQueue ? 'primary' : 'default'}
              onClick={() => handleListView(false)}
              title='Draft Room'>
              Draft Room
            </Button>
            <Button
              className={classes.queueButton}
              variant={showQueue ? 'contained' : 'outlined'}
              color={showQueue ? 'primary' : 'default'}
              onClick={() => handleListView(true)}
              title='Queue'>
              Queue
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid order={{ xs: 2, md: 1 }} item container alignItems={'flex-start'} xs={12} lg={6} className={classes.two}>
        <Button
          aria-label='select all button'
          fullWidth={isMobile}
          key='All'
          color={filteredPositions.length === 4 ? 'primary' : 'secondary'}
          variant='contained'
          style={isMobile ? { marginBottom: '1rem' } : null}
          onClick={() => handleClickAll()}>
          All
        </Button>
        {buttons}
      </Grid>
    </Grid>
  );
};

export default PlayerPositionFilter;

const useStyles = makeStyles((theme, isMobile) => ({
  root: {
    padding: '0 1rem',
  },
  input: {
    width: '100%',
  },
  one: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  two: {
    order: 1,
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  queueButton: {
    margin: '4px 8px 0 0',
  },
}));
