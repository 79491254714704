import { useLazyQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect } from 'react';

import { useAppContext } from 'context/AppProvider';

import { GET_LEAGUES } from 'api/league';
import cfg from 'config';
import { STATUS } from 'constants/geocomply';
import { GET_CURRENT_WEEK, GET_SIMULATION_STATUS } from './queries';

// Global context
export const LeagueContext = createContext({ data: [], loading: true, error: null });

export const LeagueProvider = ({ children }) => {
  const pollInterval = cfg.env === 'development' ? 5000 : 60000;
  const [{ user }] = useAppContext();
  const [fetchLeagues, { loading: leaguesLoading, error: leaguesError, data: leaguesData }] = useLazyQuery(GET_LEAGUES);
  const [fetchSimStatus, { loading: simStatusLoading, data: simStatusData }] = useLazyQuery(GET_SIMULATION_STATUS, { pollInterval });
  const [fetchWeek, { loading: weekLoading, data: weekData }] = useLazyQuery(GET_CURRENT_WEEK, { pollInterval });
  const savedStatus = localStorage.getItem('kycStatus');
  const parsedStatus = JSON.parse(savedStatus);

  useEffect(() => {
    if (!user || parsedStatus !== STATUS.VERIFIED || !parsedStatus) {
      return;
    }
    fetchLeagues();
    fetchWeek();
    if (cfg.env === 'development') {
      fetchSimStatus();
    }
  }, [user, parsedStatus]);

  return (
    <LeagueContext.Provider
      value={{
        data: { ...(leaguesData || {}), ...(weekData?.currentWeek || {}), ...(simStatusData || {}) },
        loading: leaguesLoading || weekLoading || simStatusLoading,
        error: leaguesError,
      }}>
      {children}
    </LeagueContext.Provider>
  );
};

export const useLeagueContext = () => useContext(LeagueContext);

export default LeagueProvider;
