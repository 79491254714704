export const dummyArray = {
  "players": [
    {
      "playerId": 1,
      "fullName": "Patrick Mahomes",
      "fantasyPosition": "QB",
      "team": "KC",
      "byeWeek": 12,
      "positionRank": 1,
      "fantasyPoints": 35,
      "averagePoints": 20,
      "photoUrl": "https://example.com/patrick-mahomes.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 2,
      "fullName": "Christian McCaffrey",
      "fantasyPosition": "RB",
      "team": "CAR",
      "byeWeek": 13,
      "positionRank": 2,
      "fantasyPoints": 20,
      "averagePoints": 20,
      "photoUrl": "https://example.com/christian-mccaffrey.jpg",
      "currentInjuryStatus": "Injured",
      "currentInjury": "Ankle sprain",
      "isAvailable": false,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 3,
      "fullName": "Travis Kelce",
      "fantasyPosition": "TE",
      "team": "KC",
      "byeWeek": 12,
      "positionRank": 3,
      "fantasyPoints": 15,
      "averagePoints": 20,
      "photoUrl": "https://example.com/travis-kelce.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 4,
      "fullName": "Davante Adams",
      "fantasyPosition": "WR",
      "team": "GB",
      "byeWeek": 13,
      "positionRank": 4,
      "fantasyPoints": 10,
      "averagePoints": 20,
      "photoUrl": "https://example.com/davante-adams.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 5,
      "fullName": "Alvin Kamara",
      "fantasyPosition": "RB",
      "team": "NO",
      "byeWeek": 6,
      "positionRank": 5,
      "fantasyPoints": 5,
      "averagePoints": 20,
      "photoUrl": "https://example.com/alvin-kamara.jpg",
      "currentInjuryStatus": "Injured",
      "currentInjury": "Knee injury",
      "isAvailable": false,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 6,
      "fullName": "Josh Allen",
      "fantasyPosition": "QB",
      "team": "BUF",
      "byeWeek": 7,
      "positionRank": 1,
      "fantasyPoints": 35,
      "averagePoints": 20,
      "photoUrl": "https://example.com/josh-allen.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 7,
      "fullName": "Derrick Henry",
      "fantasyPosition": "RB",
      "team": "TEN",
      "byeWeek": 13,
      "positionRank": 3,
      "fantasyPoints": 20,
      "averagePoints": 20,
      "photoUrl": "https://example.com/derrick-henry.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 8,
      "fullName": "Stefon Diggs",
      "fantasyPosition": "WR",
      "team": "BUF",
      "byeWeek": 7,
      "positionRank": 2,
      "fantasyPoints": 15,
      "averagePoints": 20,
      "photoUrl": "https://example.com/stefon-diggs.jpg",
      "currentInjuryStatus": "Injured",
      "currentInjury": "Hamstring injury",
      "isAvailable": false,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 9,
      "fullName": "George Kittle",
      "fantasyPosition": "TE",
      "team": "SF",
      "byeWeek": 6,
      "positionRank": 4,
      "fantasyPoints": 20,
      "averagePoints": 20,
      "photoUrl": "https://example.com/george-kittle.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    },
    {
      "playerId": 10,
      "fullName": "Tyreek Hill",
      "fantasyPosition": "WR",
      "team": "KC",
      "byeWeek": 12,
      "positionRank": 5,
      "fantasyPoints": 5,
      "averagePoints": 20,
      "photoUrl": "https://example.com/tyreek-hill.jpg",
      "currentInjuryStatus": null,
      "currentInjury": null,
      "isAvailable": true,
      "yards": 69,
      "touchdowns": 6,
    }
  ]
};

export default dummyArray;
